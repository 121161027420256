import withRoot from "../../utils/withRoot";
import React from "react";
import SEO from "../../components/SEO";
import Page from "../../components/Page";
import Title from '../../components/Title'
import Para from '../../components/Para'

const Contact = props => {
    return (
        <Page title="Contact">
            <SEO title="Contact"/>

            <Title level='h3'>Tell us about your problems</Title>
            <Para>
                We would love to hear about your problems - or the IT-related ones at least.
                So please get in touch for an informal discussion.
            </Para>
            <Para>
                You won’t find yourself talking to a pushy salesman, because we don’t have salespeople.
                All enquiries are handled by a friendly consultant, so you are talking to someone knowledgeable right from the start.
            </Para>
            <Para>
                If we think can help you, we will ask you if you would like to schedule a longer meeting.
                And even if we don't end up working with you, we are always interested to learn more about what people need, so we can improve the services we offer.
            </Para>


            <Para>Please contact Paul Boocock at Ashridge Technologies.</Para>
            <Title level='h4'>Email</Title>
            <Para>paulb@ashridgetech.com</Para>
            <Title level='h4'>Phone</Title>
            <Para>+44 (0)7802 872093</Para>
            <Title level='h4'>Company Details</Title>
            <Para>Ashridge Technologies Ltd is a company registered in England and Wales No 3073011.  Registered office at 1 Royal Road, St Albans AL1 4LQ</Para>


        </Page>
    );
};

export default withRoot(Contact);
